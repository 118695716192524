// 用户管理中的网络请求

import { request } from './request'

// 获取用户列表
export function getUserList (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/user/list',
    params
  })
}

// 修改用户状态
export function changUserStateRequest (uid, type) {
  return request({
    url: `users/${uid}/state/${type}`,
    method: 'put'
  })
}

// 添加新用户
export function addUserRequest (userinfo) {
  return request({
    url: '/users',
    method: 'post',
    data: { ...userinfo }
  })
}

// 根据ID查询用户信息
export function getUserByIdRequest (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/user/by-id',
    method: 'get',
    params
  })
}

// 编辑用户
export function editUserByIdRequest (userInfo) {
  return request({
    url: `/users/${userInfo.id}`,
    method: 'put',
    data: {
      email: userInfo.email,
      mobile: userInfo.mobile
    }
  })
}

// 删除用户
export function removeUserByIdRequest (id) {
  return request({
    url: `/users/${id}`,
    method: 'delete'
  })
}

// 分配用户角色
export function allotNewRoleRequest (userId, roleId) {
  return request({
    url: `users/${userId}/role`,
    method: 'put',
    data: {
      rid: roleId
    }
  })
}

export function getWithDrawListRequest (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/with-draw/list',
    method: 'get',
    params
  })
}

export function getWithDrawApplyDetailRequest (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/with-draw/user/apply/info',
    method: 'get',
    params
  })
}

export function updateWithDrawRequest (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/with-draw/update',
    method: 'post',
    data: { ...params }
  })
}

export function addSystemUserRequest (info) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/user/system/add',
    method: 'post',
    data: { ...info }
  })
}

export function updateSystemUserRequest (info) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/user/system/update',
    method: 'post',
    data: { ...info }
  })
}

export function listTeamApply (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/team-apply/list',
    params
  })
}

export function getTeamApply (params) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/team-apply/detail',
    params
  })
}

// 执行审核
export function auditTermApply (data) {
  return request({
    baseUrl: window.g.USER_URL,
    url: '/team-apply/audit',
    method: 'post',
    data
  })
}
