<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>店长申请</template>
      <template v-slot:secondMenu>审核详情</template>
    </breadcrumb-nav>
    <el-card shadow="never" class="standard-margin">
      <span class="font-title-medium">审核详情信息</span>
      <div class="form-container-border">
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small">姓名</el-col>
          <el-col class="form-border font-small" :span="18">{{applyItem.name}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">电话</el-col>
          <el-col class="form-border font-small" :span="18">{{applyItem.mobile}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">详细地址</el-col>
          <el-col class="form-border font-small" :span="18">{{applyItem.detailAddr}}</el-col>
        </el-row>
        <el-row v-for="item in applyItem.content" v-bind:key="item.title">
          <el-col class="form-border form-left-bg font-small" :span="6">{{item.title}}</el-col>
          <el-col class="form-border font-small" :span="18">{{item.value}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">当前状态</el-col>
          <el-col class="form-border font-small form-color" :span="18">{{applyItem.applyStatus | formatStatus}}</el-col>
        </el-row>
        <el-row v-show="applyItem.applyStatus===1">
          <el-col class="form-border form-left-bg font-small" :span="6" style="height:52px;line-height:32px">审核备注</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-input  size="small" v-model="updateParam.auditDesc" style="width:200px;margin-left: 10px"></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="applyItem.applyStatus===2 || applyItem.applyStatus===3">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">审核时间</el-col>
          <el-col class="form-border font-small" :span="18">{{applyItem.updateTime | formatDate}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">审核备注</el-col>
          <el-col class="form-border font-small" :span="18">{{applyItem.auditDesc || '无'}}</el-col>
        </el-row>
      </div>
      <div style="margin-top:15px;text-align: center" v-show="applyItem.applyStatus===1">
        <el-button type="primary" size="small" @click="handleUpdateStatus(2)">同意</el-button>
        <el-button type="danger" size="small" @click="handleUpdateStatus(3)">拒绝</el-button>
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </div>
      <div style="margin-top:15px;text-align: center" v-show="applyItem.applyStatus!==1">
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { auditTermApply, getTeamApply } from '../../../network/user'
const defaultUpdateParam = {
  status: 0,
  auditDesc: '',
  applyId: 0
}
export default {
  name: 'UserTeamApplyDetail',
  components: {
    BreadcrumbNav
  },
  filters: {
    formatStatus (value) {
      switch (value) {
        case 1:
          return '申请中'
        case 2:
          return '审核通过'
        case 3:
          return '审核拒绝'
        default:
          return '未知'
      }
    },
    formatDate (value) {
      if (value === 0) {
        return '无'
      }
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      const hour = (date.getHours() + '').padStart(2, '0')
      const minute = (date.getMinutes() + '').padStart(2, '0')
      const second = (date.getSeconds() + '').padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    }
  },
  data () {
    return {
      applyId: 0,
      userId: 0,
      applyItem: {
      },
      downloadUrlPrefix: '',
      updateParam: Object.assign({}, defaultUpdateParam)
    }
  },
  created () {
    this.downloadUrlPrefix = window.g.MEDIA_DOWNLOAD_URL
    this.applyId = this.$route.query.applyId
    this.userId = this.$route.query.userId
    this.updateParam.applyId = this.applyId
    this.getDetail()
  },
  methods: {
    getDetail () {
      getTeamApply({ applyId: this.applyId, userId: this.userId }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取详情失败', 'error')
        }
        this.applyItem = result.data
        this.applyItem.detailAddr = this.applyItem.province + this.applyItem.city + this.applyItem.district + ' ' + this.applyItem.address
        this.updateParam.auditDesc = this.applyItem.auditDesc
        console.log('apply detail:', this.applyItem)
      })
    },
    handleUpdateStatus (status) {
      this.updateParam.status = status
      this.updateParam.applyId = parseInt(this.updateParam.applyId)
      console.log('updateParam:', this.updateParam)
      // eslint-disable-next-line no-unreachable
      this.$confirm('是否要进行此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        auditTermApply(this.updateParam).then(res => {
          console.log('handleUpdateStatus res:', res.data)
          this.alertMessage('操作成功', 'success')
          this.$router.back()
        })
      })
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style>
.detail-container {
}

.standard-margin {
  margin-top: 15px;
}
.form-border {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
}

.form-container-border {
  border-left: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
  margin-top: 15px;
}

.form-left-bg {
  background: #F2F6FC;
}
.form-color {
  color: red;
}
</style>
